<template>
  <div>
    <div class="content-wrapper my-3 mx-1">
      <b-row class="ion-justify-content-between mb-5">
        <b-col>
          <h1>Company's KPIs</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <b-form-group>
            <b-form-select
              @change="getCompanyKpi"
              v-model="currentCompany"
              :options="companiesList"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <div v-if="tree.length > 0">
        <b-row>
          <b-col md="12" xl="9" class="pt-4">
            <draggable
              :list="tree"
              handle=".kpi-handle"
              ghost-class="ghost-item"
            >
              <div v-for="item in tree" :key="item.id">
                <div
                  class="nestable-whappy-item d-flex align-items-center item"
                >
                  <span
                    class="ml-2 text-muted kpis-label"
                    style="width: 2.5rem"
                    >{{ item.type == 0 ? "ABS" : "INC" }}</span
                  >

                  <span class="ml-2 text-muted kpis-label" style="width: 2rem">
                    <b-icon-file-earmark-excel
                      v-if="item.UpdateMode == 1"
                    ></b-icon-file-earmark-excel>
                    <b-icon-grid v-if="item.UpdateMode == 2"></b-icon-grid>
                    <b-icon-code-slash
                      v-if="item.UpdateMode == 3"
                    ></b-icon-code-slash>
                  </span>

                  <span class="ml-2"
                    ><strong>{{ item.name }}</strong></span
                  >
                  <span class="ml-auto mr-2 kpis-label">{{
                    item.default_goal
                  }}</span>
                  <div
                    class="
                      tools-box
                      align-self-stretch
                      d-flex
                      align-items-center
                    "
                  >
                    <b-icon
                      icon="plus"
                      class="pointer"
                      v-b-toggle.sidebar-kpis
                      @click="tmpKpi.id_padre = item.id"
                    ></b-icon>
                    <b-icon
                      icon="gear-fill"
                      class="pointer"
                      v-b-toggle.sidebar-kpis
                      @click="setTmpKpi(item)"
                    ></b-icon>
                    <b-icon
                      icon="trash-fill"
                      class="pointer"
                      @click="deleteCheck(item)"
                    ></b-icon>
                  </div>
                </div>
                <div
                  class="
                    nestable-whappy-item
                    d-flex
                    align-items-center
                    mb-2
                    subItem
                  "
                  v-for="subItem in item.children"
                  :key="subItem.id"
                >
                  <span
                    class="ml-2 text-muted kpis-label"
                    style="width: 2.5rem"
                    >{{ subItem.type == 0 ? "ABS" : "INC" }}</span
                  >
                  <span class="ml-2 text-muted kpis-label" style="width: 2rem">
                    <b-icon-people-fill v-if="subItem.view == 'gruppo'" />
                    <b-icon-person-fill v-else />
                  </span>
                  <span class="ml-2"
                    ><strong>{{ subItem.name }}</strong></span
                  >
                  <span class="ml-auto mr-2 kpis-label">{{
                    subItem.default_goal
                  }}</span>

                  <div
                    class="
                      tools-box
                      align-self-stretch
                      d-flex
                      align-items-center
                    "
                  >
                    <b-icon icon="plus" style="visibility: hidden"></b-icon>
                    <b-icon
                      icon="gear-fill"
                      class="pointer"
                      v-b-toggle.sidebar-kpis
                      @click="setTmpKpi(subItem)"
                    ></b-icon>
                    <b-icon
                      icon="trash-fill"
                      class="pointer"
                      @click="deleteCheck(subItem)"
                    ></b-icon>
                  </div>
                </div>
              </div>
            </draggable>
          </b-col>
        </b-row>
      </div>
      <div v-else-if="currentCompany == null">
        Please first select a company to see the KPI list
      </div>
      <div v-else-if="isLoadingKpis">
        <b-icon icon="arrow-clockwise" animation="spin" font-scale="1"></b-icon>
        Loading KPI list...
      </div>
      <div v-else>There are not KPIs in this company</div>
      <b-row class="mt-5">
        <b-col>
          <b-button
            squared
            size="lg"
            class="button-dashed big-button"
            v-b-toggle.sidebar-kpis
            >Add new Kpi</b-button
          >
        </b-col>
        <b-col cols="1">
          <div @click="downloadKpiModel"></div>
        </b-col>
      </b-row>
    </div>
    <b-sidebar
      class="right-sidebar-subsidebar"
      id="sidebar-kpis"
      right
      shadow
      lazy
      @hidden="resetData"
    >
      <div class="content-wrapper my-3 mx-1">
        <h2 v-if="tmpKpi.id">Edit KPI</h2>
        <h2 v-else-if="tmpKpi.id_padre && !tmpKpi.id">Add subset KPI</h2>
        <h2 v-else>Add new KPI</h2>

        <b-form-group class="mt-4">
          <b-form-checkbox v-model="tmpKpi.Disponibile"
            >Available for campaigns</b-form-checkbox
          >
        </b-form-group>

        <b-form-group label="Name" class="mt-4">
          <b-form-input v-model="tmpKpi.name" />
        </b-form-group>

        <b-form-group label="Update Mode" class="mt-4">
          <b-form-select
            v-model="tmpKpi.UpdateMode"
            :options="UpdateMode_options"
          ></b-form-select>
        </b-form-group>

        <b-form-group v-if="tmpKpi.UpdateMode == 3" label="Plugin" class="mt-4">
          <b-row>
            <b-col class="col-4">
              <b-form-select
                v-model="pluginSelected"
                :options="pluginsOptions"
                class="mt-2 pl-4"
              ></b-form-select>
            </b-col>
            <b-col class="col-4">
              <b-form-select
                v-if="pluginSelected == 1"
                v-model="productSelected"
                :options="productsOptions"
                class="mt-2 pl-4"
              ></b-form-select>
            </b-col>
            <b-col class="col-4">
              <b-form-select
                v-if="pluginSelected == 1"
                v-model="valueMode_selected"
                :options="valueMode_options"
                class="mt-2 pl-4"
              ></b-form-select>
            </b-col>
          </b-row>
        </b-form-group>

        <template v-if="!tmpKpi.id_padre">
          <b-form-group label="Type" class="mt-4">
            <b-form-select
              v-model="tmpKpi.type"
              :options="type_options"
            ></b-form-select>
          </b-form-group>
        </template>

        <div class="mt-5 mb-5">
          <b-button
            squared
            variant="primary"
            size="lg"
            class="big-button"
            @click="saveKpi"
            >Confirm</b-button
          >
        </div>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import AxiosService from "../axiosServices/AxiosService";
import draggable from "vuedraggable";
// import { mapGetters } from "vuex";
export default {
  name: "KpiManagement",
  components: { draggable },
  service: null,
  data() {
    return {
      isLoadingKpis: false,
      tree: [],
      companiesList: [
        {
          value: null,
          text: "Select company",
        },
      ],
      currentCompany: null,
      companyKpisList: [],
      tmpKpi: {
        name: "",
        type: null,
        UpdateMode: null,
        Disponibile: true,
        idPlugin: 0,
        idElementPlugin: 0,
      },
      currentKpi: null,

      valueMode_selected: null,
      valueMode_options: [
        { value: null, text: "Select type for Kpi" },
        { value: 0, text: "Units" },
        { value: 1, text: "Value" },
      ],

      type_options: [
        { value: null, text: "Select type" },
        { value: 0, text: "Absolute" },
        { value: 1, text: "Incremental" },
      ],
      UpdateMode_options: [
        { value: null, text: "Select update mode" },
        { value: 1, text: "Manual" },
        { value: 2, text: "API" },
        { value: 3, text: "Plugin" },
      ],
      pluginsOptions: [
        { value: null, text: "Select plugin" },
        { value: 1, text: "Sales chat" },
      ],
      pluginSelected: null,

      productsOptions: [{ value: null, text: "Seleziona il Prodotto" }],
      productSelected: null,
    };
  },
  created() {
    this.service = new AxiosService("Company");
    //     this.listPlugins.forEach((element) => {
    //       element.text = element.title;
    //       element.value = element.id;
    //     });
  },
  mounted() {
    this.service.read().then((data) => {
      let array = data.map((item) => {
        return { value: item.id, text: item.ragione_sociale };
      });
      this.companiesList.push(...array);
    });
  },
  methods: {
    getCompanyKpi() {
      this.isLoadingKpis = true;
      this.companyKpisList.splice(0);
      this.tree.splice(0);
      if (this.currentCompany == null) {
        this.isLoadingKpis = false;
      } else {
        this.service
          .readCustomEndpoint("GlobalKpi?idCompany=" + this.currentCompany)
          .then((res) => {
            this.isLoadingKpis = false;
            this.companyKpisList = res;
            this.tree = this.buildTree(this.companyKpisList);
          });
        this.service.readCustomEndpoint("SalesChat/GetProducts").then((res) => {
          res.forEach((element) => {
            element.value = element.id;
            element.text = element.nome;
            this.productsOptions.push(element);
          });
        });
      }
    },
    downloadKpiModel() {
      this.service
        .readCustomEndpoint("KpiManager/CreateKpiModels/" + this.currentCompany)
        .then((res) => {
          var fileURL = this.$store.getters.base_address + res;

          window.location.assign(fileURL);
        });
    },
    saveKpi() {
      let item = { ...this.tmpKpi };
      item.valueMode = this.valueMode_selected;
      item.idPlugin = this.pluginSelected;
      item.idElementPlugin = this.productSelected;
      const validationObject = this.$validate("kpiGlobal", item);
      if (!validationObject.validation) {
        this.$errorToast(validationObject.messages.join(", "));
        return false;
      }

      if (item.id) {
        this.service
          .putCustomEndpoint("GlobalKpi", item.id, item)
          .then((response) => {
            if (response.id == item.id) {
              Object.keys(this.currentKpi).forEach((element) => {
                if (Object.prototype.hasOwnProperty.call(item, element)) {
                  this.currentKpi[element] = item[element];
                }
              });
              this.$root.$emit("bv::toggle::collapse", "sidebar-kpis");
              this.companyKpisList.splice(0);
              this.tree.splice(0);
              this.service
                .readCustomEndpoint(
                  "GlobalKpi?idCompany=" + this.currentCompany
                )
                .then((res) => {
                  this.companyKpisList = res;
                  this.tree = this.buildTree(this.companyKpisList);
                });
              this.$successToast();
            } else {
              this.$errorToast();
              return false;
            }
          })
          .catch(() => {
            this.$errorToast();
            return false;
          });
      } else {
        this.service
          .postCustomEndpoint(
            "GlobalKpi?idCompany=" + this.currentCompany,
            "",
            item
          )
          .then((response) => {
            if (response.id) {
              item.id = response.id;
              this.companyKpisList.push(item);
              this.$root.$emit("bv::toggle::collapse", "sidebar-kpis");
              this.companyKpisList.splice(0);
              this.tree.splice(0);
              this.service
                .readCustomEndpoint(
                  "GlobalKpi?idCompany=" + this.currentCompany
                )
                .then((res) => {
                  this.companyKpisList = res;
                  this.tree = this.buildTree(this.companyKpisList);
                });
              this.$successToast();
            } else {
              this.$errorToast();
              return false;
            }
          })
          .catch(() => {
            this.$errorToast();
            return false;
          });
      }

      this.companyKpisList.splice(0);
      this.service
        .readCustomEndpoint("GlobalKpi?idCompany=" + this.currentCompany)
        .then((res) => {
          this.companyKpisList = res;
        });
    },
    deleteKpi(id) {
      this.service
        .deleteCustomEndpoint("GlobalKpi", id)
        .then((response) => {
          if (response) {
            this.companyKpisList = this.companyKpisList.filter(
              (currentItem) => id !== currentItem.id
            );
            this.tree = this.buildTree(this.companyKpisList);
            this.$successToast();
          } else {
            this.$errorToast();
            return false;
          }
        })
        .catch(() => {
          this.$errorToast();
          return false;
        });
    },

    buildTree() {
      let list = JSON.parse(JSON.stringify(this.companyKpisList));
      if (!list) return [];
      let map = {},
        node,
        roots = [],
        i;
      for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i; // initialize the map
        list[i].children = []; // initialize the children
      }
      for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.id_padre && node.id_padre !== "0") {
          if (Object.prototype.hasOwnProperty.call(map, node.id_padre)) {
            list[map[node.id_padre]].children.push(node);
          }
          //else nodo orfano
        } else {
          roots.push(node);
        }
      }
      return roots;
    },

    deleteCheck(item) {
      if (item.children && item.children.length) {
        this.showDeleteModal = true;
      } else {
        this.deleteKpi(item.id);
      }
    },
    setTmpKpi(item) {
      let index = this.companyKpisList.findIndex((x) => x.id === item.id);
      if (index > -1) {
        this.currentKpi = this.companyKpisList[index];
        this.tmpKpi = JSON.parse(JSON.stringify(this.currentKpi));
        this.pluginSelected = this.companyKpisList[index].idPlugin;
        this.productSelected = this.companyKpisList[index].idElementPlugin;
        this.valueMode_selected = this.companyKpisList[index].valueMode;
      }
    },

    resetData() {
      console.log("reset data sidebar");
      this.tmpCompanyLanguages = [];
      // this.pluginsSelection.splice(0);
      this.valueModeSelected = null;
      this.currentKpi = null;
      this.tmpKpi = {
        id: 0,
        id_padre: 0,
        name: "",
        type: 0,
        UpdateMode: 0,
        Disponibile: true,
        idPlugin: 0,
        idElementPlugin: 0,
      };
    },
  },
};
</script>